import { subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { getLeaveHistory, LeaveHistoryMonth } from '../../api/leave'
import Employee from '../../model/employee'
import PaySlip from '../../model/paySlip'
import { formatDate, getDate } from '../../utils/date-utils'
import { getPlatform } from '../../utils/device-utils'
import { formatError, isRequestError } from '../../utils/error-utils'
import { formatDays } from '../../utils/number-utils'
import { capitalise } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Card from '../antd/card'
import Modal from '../antd/modal'
import Select from '../antd/select'
import Alert from '../elements/alert'
import DumbLink from '../elements/DumbLink'
import { Col, Row } from '../elements/grid'
import LoadingOverlay from '../widgets/LoadingOverlay'
import LeaveStatisticsExplanation from './LeaveStatisticsExplanation'

import './LeaveStatistics.css'

type Props = {
  employee: Employee
  paySlips: List<PaySlip>
}

type State = {
  years: number[]
  currentYear: number
  loading: boolean
  loaded: boolean
  history: LeaveHistoryMonth[]
}

export default function LeaveStatistics(props: Props): ReactElement | null {
  const [state, setState] = useState<State>(() => {
    const years = props.paySlips
      .filter((payslip) => payslip.settled && !payslip.termination)
      .map((payslip) => getDate(payslip.dispositionDate).getFullYear())
      .reduce((list: number[], year) => {
        if (list.some((test) => test === year)) {
          return list
        }
        return [...list, year]
      }, [])
      .sort((a, b) => b - a)
    let currentYear = subYears(getDate(), 1).getFullYear()
    if (!years.some((test) => test === currentYear)) {
      currentYear = years[years.length - 1]
    }
    return {
      years,
      currentYear: currentYear,
      loading: false,
      loaded: false,
      history: [],
    }
  })
  const [showExplanation, setShowExplanation] = useState(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (state.loaded || state.loading) {
      return
    }

    setState((state) => ({ ...state, loading: true }))
    getLeaveHistory(props.employee.id, state.currentYear)
      .then((res) => {
        setState((state) => ({ ...state, loaded: true, loading: false, history: res.data }))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }, [state, props.employee])

  if (!state.loaded && !error) {
    return <LoadingOverlay />
  }

  return (
    <div className={'leave-statistics platform-' + getPlatform()}>
      {error && <Alert type={'error'} message={formatError(error)} showIcon />}
      {state.loaded && (
        <>
          <div className="leave-statistics-header">{t('leave_statistics.title', { year: state.currentYear })}</div>
          {state.years.length > 1 && (
            <Row className="leave-statistics-years-selector">
              <Col span={12}>{t('leave_statistics.selector')}</Col>
              <Col span={12}>
                <Select
                  dropdownMatchSelectWidth={false}
                  value={state.currentYear.toString()}
                  onChange={(s: string) => {
                    const year = parseInt(s)
                    if (year !== state.currentYear) {
                      setState((state) => ({ ...state, loaded: false, loading: false, currentYear: year, history: [] }))
                    }
                  }}
                >
                  {state.years.map((year) => {
                    return (
                      <Select.Option key={year} value={year.toString()} title={year.toString()}>
                        {year}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
            </Row>
          )}
          <div className="leave-statistics-explain-link">
            <DumbLink onClick={() => setShowExplanation(true)}>{t('leave_statistics.explain_link')}</DumbLink>
          </div>
          {state.history.map((month) => {
            return (
              <Card key={month.month}>
                <div className="leave-statistics-card-header">{capitalise(formatDate(month.month, 'MMMM'))}</div>
                <Row>
                  <Col span={16}>{t('leave_statistics.card.contracted_work_days')}</Col>
                  <Col span={8} className="leave-statistics-card-days">
                    {formatDays(month.contractWorkDays)}
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>{t('leave_statistics.card.work_days')}</Col>
                  <Col span={8} className="leave-statistics-card-days">
                    {formatDays(month.workDays)}
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>{t('leave_statistics.card.office_days')}</Col>
                  <Col span={8} className="leave-statistics-card-days">
                    {formatDays(month.officeDays)}
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>{t('leave_statistics.card.days_off')}</Col>
                  <Col span={8} className="leave-statistics-card-days">
                    {formatDays(month.daysOff)}
                  </Col>
                </Row>
              </Card>
            )
          })}
          <div className="leave-statistics-disclaimer">{t('leave_statistics.disclaimer')}</div>
        </>
      )}

      <Modal
        key={`info`}
        visible={showExplanation}
        closable={true}
        onOk={() => setShowExplanation(false)}
        onCancel={() => setShowExplanation(false)}
        footer={null}
      >
        <LeaveStatisticsExplanation closeModal={() => setShowExplanation(false)} />
      </Modal>
    </div>
  )
}
