import { differenceInWeeks, startOfWeek } from 'date-fns'

import { DateFormat, Day } from '../model/types'
import { getDate } from './date-utils'
import { logWarning } from './log-utils'
import { t } from './translation-utils'

export const week: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export function dayToNumber(day: Day): number {
  switch (day) {
    case 'Monday':
      return 1
    case 'Tuesday':
      return 2
    case 'Wednesday':
      return 3
    case 'Thursday':
      return 4
    case 'Friday':
      return 5
    case 'Saturday':
      return 6
    case 'Sunday':
      return 0
  }
}

export function numberToDay(day: number): Day {
  switch (day) {
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    case 0:
      return 'Sunday'
    default:
      // should not happen
      throw new Error('Only numbers 0-6 are supported')
  }
}

export function formatDay(day: Day | string): string {
  switch (day) {
    case 'Monday':
      return t('common.monday')
    case 'Tuesday':
      return t('common.tuesday')
    case 'Wednesday':
      return t('common.wednesday')
    case 'Thursday':
      return t('common.thursday')
    case 'Friday':
      return t('common.friday')
    case 'Saturday':
      return t('common.saturday')
    case 'Sunday':
      return t('common.sunday')
    case 'Holiday':
      return t('common.holiday')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing day formatting: `' + day + '`')
      }
      return t('common.unknown')
  }
}

export function getCurrentWorkWeek(workCycle?: Day[][], workCycleAnchorDate?: DateFormat, today = getDate()): Day[] {
  if (!workCycle || workCycle.length === 0) {
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  }
  if (workCycle.length === 1 || !workCycleAnchorDate) {
    return workCycle[0]
  }
  const anchorStart = startOfWeek(getDate(workCycleAnchorDate), { weekStartsOn: 1 })
  const todayStart = startOfWeek(today, { weekStartsOn: 1 })
  const diff = Math.abs(differenceInWeeks(anchorStart, todayStart))
  return workCycle[diff % workCycle.length]
}
