import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import {
  TimeRegistrationBulkFields,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { approveState } from '../../utils/approve-utils'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import Alert from '../elements/alert'
import LeaveRegistrationModalForm, { ResultFields } from './LeaveRegistrationModalForm'

type Props = {
  visible: boolean
  locked: boolean
  company: Company
  employee: Employee
  timeRegistrationID?: string
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: List<LeaveType>

  createTimeRegistration: (timeReg: TimeRegistrationCreationFields) => void
  createTimeRegistrationBulk: (timeBulk: TimeRegistrationBulkFields) => void
  updateTimeRegistration: (timeReg: TimeRegistrationMutableFields) => void
}

export default function LeaveRegistrationModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, timeRegistrations } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, timeRegistrations.error, error, setError)
  }, [timeRegistrations, visible, error, setError])

  const handleSubmit = (values: ResultFields) => {
    if (props.locked) {
      return
    }
    if (props.timeRegistrationID) {
      props.updateTimeRegistration({
        id: props.timeRegistrationID,
        employeeID: props.employee.id,
        class: 'Leave',
        leaveTypeID: values.leaveTypeID,
        leaveSubTypeID: values.leaveSubTypeID,
        note: values.note,
        date: values.date,
        days: values.days,
      })
    } else {
      if (values.startDate === values.endDate) {
        props.createTimeRegistration({
          employeeID: props.employee.id,
          class: 'Leave',
          leaveTypeID: values.leaveTypeID,
          leaveSubTypeID: values.leaveSubTypeID,
          note: values.note,
          date: values.startDate,
          days: values.days,
          approved: approveState(props.employee),
          noRateAdjustment: values.noRateAdjustment,
        })
      } else {
        props.createTimeRegistrationBulk({
          employeeID: props.employee.id,
          class: 'Leave',
          leaveTypeID: values.leaveTypeID,
          leaveSubTypeID: values.leaveSubTypeID,
          note: values.note,
          startDate: values.startDate,
          endDate: values.endDate,
          approved: approveState(props.employee),
        })
      }
    }
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <LeaveRegistrationModalForm
        locked={props.locked}
        timeRegistrationID={props.timeRegistrationID}
        company={props.company}
        employee={props.employee}
        timeRegistrations={props.timeRegistrations.timeRegistrations}
        leaveTypes={props.leaveTypes}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
