import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Company from '../../model/company'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import TimeRegistration from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import CompanySetting from '../../types/company-setting'
import LeaveDuration from '../../types/leave-duration'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { isSalaried } from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatLeaveDuration, formatLeaveSubTypeName, formatLeaveTypeName } from '../../utils/format-utils'
import { getCurrentLocale } from '../../utils/language-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t, tx } from '../../utils/translation-utils'
import Form from '../antd/form'
import Select from '../antd/select'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import HelpModal from '../elements/HelpModal'
import Input from '../elements/input'
import SwitchWrapper from '../elements/SwitchWrapper'

type Props = {
  locked: boolean
  timeRegistrationID?: string
  company: Company
  employee: Employee
  leaveTypes: List<LeaveType>
  timeRegistrations: List<TimeRegistration>
}

type Fields = {
  leaveTypeID?: string
  date: Date
  period: [Date, Date | null]
  leaveDuration: LeaveDuration
  days: string
  leaveSubTypeID?: string
  note?: string
  immutable: boolean
  noRateAdjustment: boolean
}

export type ResultFields = {
  leaveTypeID: string
  date: DateFormat
  startDate: DateFormat
  endDate: DateFormat
  days: number
  leaveSubTypeID?: string
  note?: string
  noRateAdjustment: boolean
}

function LeaveRegistrationModalForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const [pickerOpen, setPickerOpen] = useState(false)

  const { decorateField, getFieldError, getFieldValue } = props

  if (getFieldValue('immutable') || props.locked) {
    let type = t('common.unknown')
    let subType
    const leaveType = props.leaveTypes.find((type) => type.id === getFieldValue('leaveTypeID'))
    if (leaveType) {
      type = formatLeaveTypeName(leaveType.name, isSalaried(props.employee))
      const leaveSubType = leaveType.leaveSubTypes.find((subType) => subType.id === getFieldValue('leaveSubTypeID'))
      if (leaveSubType) {
        subType = formatLeaveSubTypeName(leaveSubType.name)
      }
    }
    return (
      <div>
        {props.getFormError()}
        <Form.Item>
          <label>{t('common.type')}</label>
          {type}
        </Form.Item>
        {getFieldValue('leaveSubTypeID') && (
          <Form.Item>
            <label>
              {leaveType?.name === 'DenmarkDayOff'
                ? t('leave_registration.sub_type_day_off')
                : leaveType?.name === 'DenmarkSickDayPaid'
                ? t('leave_registration.sub_type_sick_day')
                : t('leave_registration.sub_type_other')}
            </label>
            {subType}
          </Form.Item>
        )}
        <Form.Item>
          <label>{t('common.date')}</label>
          {formatDate(getFieldValue('date'))}
        </Form.Item>
        <Form.Item>
          <label>{t('common.duration')}</label>
          {formatLeaveDuration(getFieldValue('leaveDuration'))}
        </Form.Item>
        <Form.Item>
          <label>{t('common.note')}</label>
          {getFieldValue('note')}
        </Form.Item>
      </div>
    )
  }

  const isEditing = !!props.timeRegistrationID
  const canEditLeaveDuration =
    isEditing ||
    (!pickerOpen && isSameDay(getFieldValue('period')[0], getFieldValue('period')[1] || getFieldValue('period')[0]))
  const contract = props.employee.activeContract
  const dateError = getFieldError('date') || getFieldError('period')

  if (!contract) {
    return null
  }

  let dayOffID = null
  let sickDayID = null
  props.leaveTypes.forEach((leaveType) => {
    if (leaveType.name === 'DenmarkDayOff') {
      dayOffID = leaveType.id
    }
    if (leaveType.name === 'DenmarkSickDayPaid') {
      sickDayID = leaveType.id
    }
  })

  const leaveTypeID = getFieldValue('leaveTypeID')
  const leaveSubTypes =
    props.leaveTypes
      .find((leaveType) => leaveType.id === leaveTypeID)
      ?.leaveSubTypes.filter((subType) => subType.employeeSelectable) || []

  const leaveTypesWithAutomaticRateAdjustment = (
    ['DenmarkVacationAccrual', 'DenmarkVacationFund', 'DenmarkVacationFundWithPension'] as const
  ).map((n) => props.leaveTypes.find((lt) => lt.name === n)?.id)

  return (
    <div>
      {props.getFormError()}
      {decorateField('leaveTypeID', {
        placeholder: t('common.type'),
        validate: (val) => (!val ? 'Type skal vælges' : null),
      })(
        <Select dropdownMatchSelectWidth={false} tabIndex={1}>
          {contract.remuneration.leave
            .filter(
              (leave) => !!leave.type && leave.type.name !== 'DenmarkFlexTime' && leave.type.name !== 'DenmarkOvertime'
            )
            .map((leave) => {
              return (
                <Select.Option key={leave.type!.id} value={leave.type!.id}>
                  {formatLeaveTypeName(leave.type!.name, isSalaried(props.employee))}
                </Select.Option>
              )
            })}
          {props.leaveTypes
            .filter(
              (leave) =>
                leave.employeeSelectable && leave.name !== 'DenmarkFlexTime' && leave.name !== 'DenmarkOvertime'
            )
            .map((leave) => {
              return (
                <Select.Option key={leave.id} value={leave.id}>
                  {formatLeaveTypeName(leave.name, isSalaried(props.employee))}
                </Select.Option>
              )
            })}
        </Select>
      )}
      {leaveSubTypes.length > 0 &&
        decorateField('leaveSubTypeID', {
          placeholder:
            leaveTypeID === dayOffID
              ? t('leave_registration.sub_type_day_off')
              : leaveTypeID === sickDayID
              ? t('leave_registration.sub_type_sick_day')
              : t('leave_registration.sub_type_other'),
        })(
          <Select dropdownMatchSelectWidth={false} tabIndex={2}>
            <Select.Option key={''} value={''}>
              {t('common.none')}
            </Select.Option>
            {leaveSubTypes.map((leaveSubType) => {
              return (
                <Select.Option key={leaveSubType.id} value={leaveSubType.id}>
                  {formatLeaveSubTypeName(leaveSubType.name)}
                </Select.Option>
              )
            })}
          </Select>
        )}
      <Form.Item validateStatus={dateError ? 'error' : 'success'}>
        {isEditing
          ? decorateField('date', {
              placeholder: t('common.date'),
              validate: (val) => {
                if (!val) {
                  return t('validation.date_is_required')
                }
                return null
              },
            })(<DatePicker allowClear={false} tabIndex={3} style={{ width: '100%' }} />)
          : decorateField('period', {
              title: t('leave_registration.period'),
              validate: (val) => {
                if (!val || val.length < 2 || val[1] === null) {
                  return t('validation.period_is_required')
                }
                return null
              },
            })(
              <DatePicker.RangePicker
                allowClear={false}
                tabIndex={3}
                style={{ width: '100%' }}
                onStateChange={(open) => setPickerOpen(open)}
              />
            )}
      </Form.Item>
      <div style={{ marginTop: '35px' }}>
        {decorateField('leaveDuration', {
          placeholder: t('common.duration'),
          validate: (val) => (!val ? t('validation.duration_must_be_chosen') : null),
        })(
          <Select dropdownMatchSelectWidth={false} tabIndex={5} disabled={!canEditLeaveDuration}>
            <Select.Option key={LeaveDuration.FULL_DAY} value={LeaveDuration.FULL_DAY}>
              {formatLeaveDuration(LeaveDuration.FULL_DAY)}
            </Select.Option>
            <Select.Option key={LeaveDuration.THREE_QUARTERS_DAY} value={LeaveDuration.THREE_QUARTERS_DAY}>
              {formatLeaveDuration(LeaveDuration.THREE_QUARTERS_DAY)}
            </Select.Option>
            <Select.Option key={LeaveDuration.HALF_DAY} value={LeaveDuration.HALF_DAY}>
              {formatLeaveDuration(LeaveDuration.HALF_DAY)}
            </Select.Option>
            <Select.Option key={LeaveDuration.QUARTER_DAY} value={LeaveDuration.QUARTER_DAY}>
              {formatLeaveDuration(LeaveDuration.QUARTER_DAY)}
            </Select.Option>
            <Select.Option key={LeaveDuration.OTHER} value={LeaveDuration.OTHER}>
              {formatLeaveDuration(LeaveDuration.OTHER)}
            </Select.Option>
          </Select>
        )}
      </div>
      {canEditLeaveDuration &&
        getFieldValue('leaveDuration') === LeaveDuration.OTHER &&
        decorateField('days', {
          placeholder: t('common.days'),
          validate: (val) => {
            if (!val) {
              return t('validation.days_is_required')
            }
            if (!val.match(/^[0-9,.]+$/) || forceParseInputNumber(val) > 5.0) {
              return t('validation.days_is_invalid')
            }
            return null
          },
        })(<Input inputMode="decimal" lang={getCurrentLocale()} />)}
      {canEditLeaveDuration &&
        getFieldValue('leaveDuration') === LeaveDuration.OTHER &&
        props.company.settingsEnabled.includes(CompanySetting.USE_ADJUSTED_WORK_WEEK_LEAVE_RATE) &&
        leaveTypesWithAutomaticRateAdjustment.includes(getFieldValue('leaveTypeID')) && (
          <SwitchWrapper id="noRateAdjustment" decorateField={decorateField}>
            {tx('leave_registration.form.no_rate_adjustment', {
              not: <strong>{t('leave_registration.form.no_rate_adjustment.not')}</strong>,
            })}
            <HelpModal>
              <p>{t('leave_registration.form.no_rate_adjustment.help.line_1')}</p>
              <p>{t('leave_registration.form.no_rate_adjustment.help.line_2')}</p>
            </HelpModal>
          </SwitchWrapper>
        )}
      <div>
        {decorateField('note', {
          placeholder: t('common.note'),
        })(<Input tabIndex={8} />)}
      </div>
      <Form.Item>
        <Button htmlType="submit" size="large" className="ant-btn-secondary" tabIndex={8}>
          {t('leave_registration.register_leave')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      date: getDate(),
      period: [getDate(), getDate()],
      leaveDuration: LeaveDuration.FULL_DAY,
      days: '1',
      immutable: false,
      noRateAdjustment: false,
    }
    const approvedMeansImmutable = !props.company.autoApproveTimeRegistrationLeave
    const timeRegistration = props.timeRegistrations.find(
      (timeRegistration) => timeRegistration.id === props.timeRegistrationID
    )
    if (timeRegistration) {
      fields.date = getDate(timeRegistration.date)
      fields.leaveTypeID = timeRegistration.leaveTypeID
      fields.leaveSubTypeID = timeRegistration.leaveSubTypeID
      fields.note = timeRegistration.note
      switch (timeRegistration.days) {
        case 1:
          fields.leaveDuration = LeaveDuration.FULL_DAY
          break
        case 0.75:
          fields.leaveDuration = LeaveDuration.THREE_QUARTERS_DAY
          break
        case 0.5:
          fields.leaveDuration = LeaveDuration.HALF_DAY
          break
        case 0.25:
          fields.leaveDuration = LeaveDuration.QUARTER_DAY
          break
        default:
          fields.leaveDuration = LeaveDuration.OTHER
          fields.days = formatInputNumber(timeRegistration.days)
          break
      }
      fields.immutable = timeRegistration.immutable
      if (timeRegistration.approved && approvedMeansImmutable) {
        fields.immutable = true
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'days':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      case 'period':
        setByPath(values, key, val)
        if (val && !isSameDay((val as Date[])[0] as Date, (val as Date[])[1] as Date)) {
          setByPath(values, 'leaveDuration', LeaveDuration.FULL_DAY)
        }
        break
      case 'leaveDuration':
        setByPath(values, key, val)
        if (val !== LeaveDuration.OTHER) {
          setByPath(values, 'noRateAdjustment', false)
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      leaveTypeID: values.leaveTypeID || '',
      date: formatAPIDate(values.date),
      startDate: formatAPIDate(values.period[0]),
      endDate: formatAPIDate(values.period[1]!),
      days: 1,
      leaveSubTypeID: !values.leaveSubTypeID ? undefined : values.leaveSubTypeID,
      note: values.note,
      noRateAdjustment: values.noRateAdjustment,
    }
    switch (values.leaveDuration) {
      case LeaveDuration.FULL_DAY:
        result.days = 1
        break
      case LeaveDuration.THREE_QUARTERS_DAY:
        result.days = 0.75
        break
      case LeaveDuration.HALF_DAY:
        result.days = 0.5
        break
      case LeaveDuration.QUARTER_DAY:
        result.days = 0.25
        break
      default:
        result.days = forceParseInputNumber(values.days)
        break
    }
    return result
  },
})(LeaveRegistrationModalForm)
