import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import { decorateFieldSignature, GenericFields } from '../../utils/form-utils'
import Switch from '../antd/switch'

type Props<Fields extends GenericFields> = {
  id: keyof Fields
  decorateField: decorateFieldSignature<Fields>
  style?: CSSProperties
  textStyle?: CSSProperties
  children: ReactNode
}

export default function SwitchWrapper<Fields extends GenericFields>(props: Props<Fields>): ReactElement {
  return (
    <div className="ant-switch-wrapper" style={props.style}>
      {props.decorateField(props.id, {
        skipWrapper: true,
        skipLabel: true,
        valueOnChecked: true,
        noBlur: true,
      })(<Switch />)}
      <span className="ant-switch-text" style={props.textStyle}>
        {props.children}
      </span>
    </div>
  )
}
