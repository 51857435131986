import { EmployeeAppFrontPageChoice } from '../model/employee'
import { LeaveSubTypeName, LeaveTypeName } from '../model/leaveType'
import { TimeRegistrationClass } from '../model/timeRegistration'
import CountryCode from '../types/country-code'
import Gender from '../types/gender'
import LeaveDuration from '../types/leave-duration'
import MfaChannel from '../types/mfa-channel'
import ReimbursementVoucherApprovalState from '../types/reimbursement-voucher-approval-state'
import { logWarning } from './log-utils'
import { t } from './translation-utils'

export function formatCountryCode(code: CountryCode) {
  switch (code) {
    case CountryCode.AF:
      return t('country_codes.af')
    case CountryCode.AX:
      return t('country_codes.ax')
    case CountryCode.AL:
      return t('country_codes.al')
    case CountryCode.DZ:
      return t('country_codes.dz')
    case CountryCode.AS:
      return t('country_codes.as')
    case CountryCode.AD:
      return t('country_codes.ad')
    case CountryCode.AO:
      return t('country_codes.ao')
    case CountryCode.AI:
      return t('country_codes.ai')
    case CountryCode.AQ:
      return t('country_codes.aq')
    case CountryCode.AG:
      return t('country_codes.ag')
    case CountryCode.AR:
      return t('country_codes.ar')
    case CountryCode.AM:
      return t('country_codes.am')
    case CountryCode.AW:
      return t('country_codes.aw')
    case CountryCode.AU:
      return t('country_codes.au')
    case CountryCode.AT:
      return t('country_codes.at')
    case CountryCode.AZ:
      return t('country_codes.az')
    case CountryCode.BS:
      return t('country_codes.bs')
    case CountryCode.BH:
      return t('country_codes.bh')
    case CountryCode.BD:
      return t('country_codes.bd')
    case CountryCode.BB:
      return t('country_codes.bb')
    case CountryCode.BY:
      return t('country_codes.by')
    case CountryCode.BE:
      return t('country_codes.be')
    case CountryCode.BZ:
      return t('country_codes.bz')
    case CountryCode.BJ:
      return t('country_codes.bj')
    case CountryCode.BM:
      return t('country_codes.bm')
    case CountryCode.BT:
      return t('country_codes.bt')
    case CountryCode.BO:
      return t('country_codes.bo')
    case CountryCode.BQ:
      return t('country_codes.bq')
    case CountryCode.BA:
      return t('country_codes.ba')
    case CountryCode.BW:
      return t('country_codes.bw')
    case CountryCode.BV:
      return t('country_codes.bv')
    case CountryCode.BR:
      return t('country_codes.br')
    case CountryCode.IO:
      return t('country_codes.io')
    case CountryCode.BN:
      return t('country_codes.bn')
    case CountryCode.BG:
      return t('country_codes.bg')
    case CountryCode.BF:
      return t('country_codes.bf')
    case CountryCode.BI:
      return t('country_codes.bi')
    case CountryCode.CV:
      return t('country_codes.cv')
    case CountryCode.KH:
      return t('country_codes.kh')
    case CountryCode.CM:
      return t('country_codes.cm')
    case CountryCode.CA:
      return t('country_codes.ca')
    case CountryCode.KY:
      return t('country_codes.ky')
    case CountryCode.CF:
      return t('country_codes.cf')
    case CountryCode.TD:
      return t('country_codes.td')
    case CountryCode.CL:
      return t('country_codes.cl')
    case CountryCode.CN:
      return t('country_codes.cn')
    case CountryCode.CX:
      return t('country_codes.cx')
    case CountryCode.CC:
      return t('country_codes.cc')
    case CountryCode.CO:
      return t('country_codes.co')
    case CountryCode.KM:
      return t('country_codes.km')
    case CountryCode.CG:
      return t('country_codes.cg')
    case CountryCode.CD:
      return t('country_codes.cd')
    case CountryCode.CK:
      return t('country_codes.ck')
    case CountryCode.CR:
      return t('country_codes.cr')
    case CountryCode.CI:
      return t('country_codes.ci')
    case CountryCode.HR:
      return t('country_codes.hr')
    case CountryCode.CU:
      return t('country_codes.cu')
    case CountryCode.CW:
      return t('country_codes.cw')
    case CountryCode.CY:
      return t('country_codes.cy')
    case CountryCode.CZ:
      return t('country_codes.cz')
    case CountryCode.DK:
      return t('country_codes.dk')
    case CountryCode.DJ:
      return t('country_codes.dj')
    case CountryCode.DM:
      return t('country_codes.dm')
    case CountryCode.DO:
      return t('country_codes.do')
    case CountryCode.EC:
      return t('country_codes.ec')
    case CountryCode.EG:
      return t('country_codes.eg')
    case CountryCode.SV:
      return t('country_codes.sv')
    case CountryCode.GQ:
      return t('country_codes.gq')
    case CountryCode.ER:
      return t('country_codes.er')
    case CountryCode.EE:
      return t('country_codes.ee')
    case CountryCode.SZ:
      return t('country_codes.sz')
    case CountryCode.ET:
      return t('country_codes.et')
    case CountryCode.FK:
      return t('country_codes.fk')
    case CountryCode.FO:
      return t('country_codes.fo')
    case CountryCode.FJ:
      return t('country_codes.fj')
    case CountryCode.FI:
      return t('country_codes.fi')
    case CountryCode.FR:
      return t('country_codes.fr')
    case CountryCode.GF:
      return t('country_codes.gf')
    case CountryCode.PF:
      return t('country_codes.pf')
    case CountryCode.TF:
      return t('country_codes.tf')
    case CountryCode.GA:
      return t('country_codes.ga')
    case CountryCode.GM:
      return t('country_codes.gm')
    case CountryCode.GE:
      return t('country_codes.ge')
    case CountryCode.DE:
      return t('country_codes.de')
    case CountryCode.GH:
      return t('country_codes.gh')
    case CountryCode.GI:
      return t('country_codes.gi')
    case CountryCode.GR:
      return t('country_codes.gr')
    case CountryCode.GL:
      return t('country_codes.gl')
    case CountryCode.GD:
      return t('country_codes.gd')
    case CountryCode.GP:
      return t('country_codes.gp')
    case CountryCode.GU:
      return t('country_codes.gu')
    case CountryCode.GT:
      return t('country_codes.gt')
    case CountryCode.GG:
      return t('country_codes.gg')
    case CountryCode.GN:
      return t('country_codes.gn')
    case CountryCode.GW:
      return t('country_codes.gw')
    case CountryCode.GY:
      return t('country_codes.gy')
    case CountryCode.HT:
      return t('country_codes.ht')
    case CountryCode.HM:
      return t('country_codes.hm')
    case CountryCode.VA:
      return t('country_codes.va')
    case CountryCode.HN:
      return t('country_codes.hn')
    case CountryCode.HK:
      return t('country_codes.hk')
    case CountryCode.HU:
      return t('country_codes.hu')
    case CountryCode.IS:
      return t('country_codes.is')
    case CountryCode.IN:
      return t('country_codes.in')
    case CountryCode.ID:
      return t('country_codes.id')
    case CountryCode.IR:
      return t('country_codes.ir')
    case CountryCode.IQ:
      return t('country_codes.iq')
    case CountryCode.IE:
      return t('country_codes.ie')
    case CountryCode.IM:
      return t('country_codes.im')
    case CountryCode.IL:
      return t('country_codes.il')
    case CountryCode.IT:
      return t('country_codes.it')
    case CountryCode.JM:
      return t('country_codes.jm')
    case CountryCode.JP:
      return t('country_codes.jp')
    case CountryCode.JE:
      return t('country_codes.je')
    case CountryCode.JO:
      return t('country_codes.jo')
    case CountryCode.KZ:
      return t('country_codes.kz')
    case CountryCode.KE:
      return t('country_codes.ke')
    case CountryCode.KI:
      return t('country_codes.ki')
    case CountryCode.KP:
      return t('country_codes.kp')
    case CountryCode.KR:
      return t('country_codes.kr')
    case CountryCode.KW:
      return t('country_codes.kw')
    case CountryCode.KG:
      return t('country_codes.kg')
    case CountryCode.LA:
      return t('country_codes.la')
    case CountryCode.LV:
      return t('country_codes.lv')
    case CountryCode.LB:
      return t('country_codes.lb')
    case CountryCode.LS:
      return t('country_codes.ls')
    case CountryCode.LR:
      return t('country_codes.lr')
    case CountryCode.LY:
      return t('country_codes.ly')
    case CountryCode.LI:
      return t('country_codes.li')
    case CountryCode.LT:
      return t('country_codes.lt')
    case CountryCode.LU:
      return t('country_codes.lu')
    case CountryCode.MO:
      return t('country_codes.mo')
    case CountryCode.MK:
      return t('country_codes.mk')
    case CountryCode.MG:
      return t('country_codes.mg')
    case CountryCode.MW:
      return t('country_codes.mw')
    case CountryCode.MY:
      return t('country_codes.my')
    case CountryCode.MV:
      return t('country_codes.mv')
    case CountryCode.ML:
      return t('country_codes.ml')
    case CountryCode.MT:
      return t('country_codes.mt')
    case CountryCode.MH:
      return t('country_codes.mh')
    case CountryCode.MQ:
      return t('country_codes.mq')
    case CountryCode.MR:
      return t('country_codes.mr')
    case CountryCode.MU:
      return t('country_codes.mu')
    case CountryCode.YT:
      return t('country_codes.yt')
    case CountryCode.MX:
      return t('country_codes.mx')
    case CountryCode.FM:
      return t('country_codes.fm')
    case CountryCode.MD:
      return t('country_codes.md')
    case CountryCode.MC:
      return t('country_codes.mc')
    case CountryCode.MN:
      return t('country_codes.mn')
    case CountryCode.ME:
      return t('country_codes.me')
    case CountryCode.MS:
      return t('country_codes.ms')
    case CountryCode.MA:
      return t('country_codes.ma')
    case CountryCode.MZ:
      return t('country_codes.mz')
    case CountryCode.MM:
      return t('country_codes.mm')
    case CountryCode.NA:
      return t('country_codes.na')
    case CountryCode.NR:
      return t('country_codes.nr')
    case CountryCode.NP:
      return t('country_codes.np')
    case CountryCode.NL:
      return t('country_codes.nl')
    case CountryCode.NC:
      return t('country_codes.nc')
    case CountryCode.NZ:
      return t('country_codes.nz')
    case CountryCode.NI:
      return t('country_codes.ni')
    case CountryCode.NE:
      return t('country_codes.ne')
    case CountryCode.NG:
      return t('country_codes.ng')
    case CountryCode.NU:
      return t('country_codes.nu')
    case CountryCode.NF:
      return t('country_codes.nf')
    case CountryCode.MP:
      return t('country_codes.mp')
    case CountryCode.NO:
      return t('country_codes.no')
    case CountryCode.OM:
      return t('country_codes.om')
    case CountryCode.PK:
      return t('country_codes.pk')
    case CountryCode.PW:
      return t('country_codes.pw')
    case CountryCode.PS:
      return t('country_codes.ps')
    case CountryCode.PA:
      return t('country_codes.pa')
    case CountryCode.PG:
      return t('country_codes.pg')
    case CountryCode.PY:
      return t('country_codes.py')
    case CountryCode.PE:
      return t('country_codes.pe')
    case CountryCode.PH:
      return t('country_codes.ph')
    case CountryCode.PN:
      return t('country_codes.pn')
    case CountryCode.PL:
      return t('country_codes.pl')
    case CountryCode.PT:
      return t('country_codes.pt')
    case CountryCode.PR:
      return t('country_codes.pr')
    case CountryCode.QA:
      return t('country_codes.qa')
    case CountryCode.RE:
      return t('country_codes.re')
    case CountryCode.RO:
      return t('country_codes.ro')
    case CountryCode.RU:
      return t('country_codes.ru')
    case CountryCode.RW:
      return t('country_codes.rw')
    case CountryCode.BL:
      return t('country_codes.bl')
    case CountryCode.SH:
      return t('country_codes.sh')
    case CountryCode.KN:
      return t('country_codes.kn')
    case CountryCode.LC:
      return t('country_codes.lc')
    case CountryCode.MF:
      return t('country_codes.mf')
    case CountryCode.PM:
      return t('country_codes.pm')
    case CountryCode.VC:
      return t('country_codes.vc')
    case CountryCode.WS:
      return t('country_codes.ws')
    case CountryCode.SM:
      return t('country_codes.sm')
    case CountryCode.ST:
      return t('country_codes.st')
    case CountryCode.SA:
      return t('country_codes.sa')
    case CountryCode.SN:
      return t('country_codes.sn')
    case CountryCode.RS:
      return t('country_codes.rs')
    case CountryCode.SC:
      return t('country_codes.sc')
    case CountryCode.SL:
      return t('country_codes.sl')
    case CountryCode.SG:
      return t('country_codes.sg')
    case CountryCode.SX:
      return t('country_codes.sx')
    case CountryCode.SK:
      return t('country_codes.sk')
    case CountryCode.SI:
      return t('country_codes.si')
    case CountryCode.SB:
      return t('country_codes.sb')
    case CountryCode.SO:
      return t('country_codes.so')
    case CountryCode.ZA:
      return t('country_codes.za')
    case CountryCode.GS:
      return t('country_codes.gs')
    case CountryCode.SS:
      return t('country_codes.ss')
    case CountryCode.ES:
      return t('country_codes.es')
    case CountryCode.LK:
      return t('country_codes.lk')
    case CountryCode.SD:
      return t('country_codes.sd')
    case CountryCode.SR:
      return t('country_codes.sr')
    case CountryCode.SJ:
      return t('country_codes.sj')
    case CountryCode.SE:
      return t('country_codes.se')
    case CountryCode.CH:
      return t('country_codes.ch')
    case CountryCode.SY:
      return t('country_codes.sy')
    case CountryCode.TW:
      return t('country_codes.tw')
    case CountryCode.TJ:
      return t('country_codes.tj')
    case CountryCode.TZ:
      return t('country_codes.tz')
    case CountryCode.TH:
      return t('country_codes.th')
    case CountryCode.TL:
      return t('country_codes.tl')
    case CountryCode.TG:
      return t('country_codes.tg')
    case CountryCode.TK:
      return t('country_codes.tk')
    case CountryCode.TO:
      return t('country_codes.to')
    case CountryCode.TT:
      return t('country_codes.tt')
    case CountryCode.TN:
      return t('country_codes.tn')
    case CountryCode.TR:
      return t('country_codes.tr')
    case CountryCode.TM:
      return t('country_codes.tm')
    case CountryCode.TC:
      return t('country_codes.tc')
    case CountryCode.TV:
      return t('country_codes.tv')
    case CountryCode.UG:
      return t('country_codes.ug')
    case CountryCode.UA:
      return t('country_codes.ua')
    case CountryCode.AE:
      return t('country_codes.ae')
    case CountryCode.GB:
      return t('country_codes.gb')
    case CountryCode.US:
      return t('country_codes.us')
    case CountryCode.UM:
      return t('country_codes.um')
    case CountryCode.UY:
      return t('country_codes.uy')
    case CountryCode.UZ:
      return t('country_codes.uz')
    case CountryCode.VU:
      return t('country_codes.vu')
    case CountryCode.VE:
      return t('country_codes.ve')
    case CountryCode.VN:
      return t('country_codes.vn')
    case CountryCode.VG:
      return t('country_codes.vg')
    case CountryCode.VI:
      return t('country_codes.vi')
    case CountryCode.WF:
      return t('country_codes.wf')
    case CountryCode.EH:
      return t('country_codes.eh')
    case CountryCode.YE:
      return t('country_codes.ye')
    case CountryCode.ZM:
      return t('country_codes.zm')
    case CountryCode.ZW:
      return t('country_codes.zw')
    case CountryCode.XI:
      return t('country_codes.xi')
    case CountryCode.XU:
      return t('country_codes.xu')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing country code formatting: `' + code + '`')
      }
      return t('common.unknown')
  }
}

export function formatGender(gender: Gender) {
  switch (gender) {
    case Gender.FEMALE:
      return t('gender.female')
    case Gender.MALE:
      return t('gender.male')
    case Gender.UNKNOWN:
      return t('common.unknown')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing gender formatting: `' + gender + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveDuration(duration: LeaveDuration) {
  switch (duration) {
    case LeaveDuration.FULL_DAY:
      return t('leave_durations.full_day')
    case LeaveDuration.THREE_QUARTERS_DAY:
      return t('leave_durations.three_quarters_day')
    case LeaveDuration.HALF_DAY:
      return t('leave_durations.half_day')
    case LeaveDuration.QUARTER_DAY:
      return t('leave_durations.quarter_day')
    case LeaveDuration.OTHER:
      return t('leave_durations.other')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing leave duration formatting: `' + duration + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveTypeName(name: LeaveTypeName, isSalaried: boolean) {
  switch (name) {
    case 'DenmarkVacationPaid':
      return t('leave_type_names.vacation_paid')
    case 'DenmarkVacationAccrual':
      return t('leave_type_names.vacation_accrual')
    case 'DenmarkVacationPaidAdditional':
      return t('leave_type_names.vacation_paid_additional')
    case 'DenmarkVacationNoPay':
      return t('leave_type_names.vacation_no_pay')
    case 'DenmarkVacationFund':
      return t('leave_type_names.vacation_fund')
    case 'DenmarkVacationFundWithPension':
      return t('leave_type_names.vacation_fund_pension')
    case 'DenmarkPersonalDay':
      return t('leave_type_names.personal_day')
    case 'DenmarkPersonalTimeAccrual':
      return t('leave_type_names.personal_day_accrual')
    case 'DenmarkOptionalVacation':
      return t('leave_type_names.optional_vacation')
    case 'DenmarkExtraVacationAccrual':
      return t('leave_type_names.optional_vacation_accrual')
    case 'DenmarkSickDayPaid':
      if (isSalaried) {
        return t('leave_type_names.sick_day_paid_employer_paid')
      }
      return t('leave_type_names.sick_day_paid')
    case 'DenmarkUnpaidDayOff':
      return t('leave_type_names.unpaid_day_off')
    case 'DenmarkDayOff':
      if (isSalaried) {
        return t('leave_type_names.day_off_employer_paid')
      }
      return t('leave_type_names.day_off')
    case 'DenmarkRemoteWorkDay':
      return t('leave_type_names.remote_work')
    case 'DenmarkLeaveDay':
      return t('leave_type_names.leave_day')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing leave type name formatting: `' + name + '`')
      }
      return t('common.unknown')
  }
}

export function formatMfaChannel(channel: MfaChannel) {
  switch (channel) {
    case MfaChannel.SMS:
      return t('mfa_channels.sms')
    case MfaChannel.TOTP:
      return t('mfa_channels.totp')
    case MfaChannel.NONE:
      return t('mfa_channels.none')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing mfa channel formatting: `' + channel + '`')
      }
      return t('common.unknown')
  }
}

export function formatReimbursementVoucherApprovalState(state: ReimbursementVoucherApprovalState) {
  switch (state) {
    case ReimbursementVoucherApprovalState.DRAFT:
      return t('reimbursement_voucher_approval_states.draft')
    case ReimbursementVoucherApprovalState.READY:
      return t('reimbursement_voucher_approval_states.ready')
    case ReimbursementVoucherApprovalState.APPROVED:
      return t('reimbursement_voucher_approval_states.approved')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing reimbursement voucher state formatting: `' + state + '`')
      }
      return t('common.unknown')
  }
}

export function formatTimeRegistrationType(type: TimeRegistrationClass) {
  switch (type) {
    case 'Hours':
      return t('time_registration_types.hours')
    case 'Leave':
      return t('time_registration_types.leave')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing time registration type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveSubTypeName(leaveSubTypeName: LeaveSubTypeName) {
  switch (leaveSubTypeName) {
    case "Child's first sick day":
      return t('leave.sub_type.child_first_sick_day')
    case "Child's second sick day":
      return t('leave.sub_type.child_second_sick_day')
    case 'Anniversary':
      return t('leave.sub_type.anniversary')
    case 'Moving day':
      return t('leave.sub_type.moving_day')
    case 'Mourning day':
      return t('leave.sub_type.mourning_day')
    case 'Time off in lieu':
      return t('leave.sub_type.time_off_in_lieu')
    case 'Parental leave':
      return t('leave.sub_type.parental_leave')
    case 'COVID-19':
      return t('leave.sub_type.covid_19')
    case 'Paragraph 56':
      return t('leave.sub_type.paragraph_56')
    case 'Course':
      return t('leave.sub_type.course')
    case 'Parental leave last 4 weeks before term':
      return t('leave.sub_type.parental_leave_last_4_weeks_before_term')
    case 'Parental leave first 2 weeks':
      return t('leave.sub_type.parental_leave_first_2_weeks')
    case 'Parental leave after 2 weeks':
      return t('leave.sub_type.parental_leave_after_2_weeks')
    case 'Parental leave sickness':
      return t('leave.sub_type.parental_leave_sickness')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown leave sub type name: `' + leaveSubTypeName + '`')
      }
      return t('common.unknown')
  }
}

export function formatEmployeeAppFrontPageChoice(choice: EmployeeAppFrontPageChoice, hasFlex = false) {
  switch (choice) {
    case 'Time Registration':
      return t('front_page_choice.time_registration')
    case 'Time Box Registration':
      return hasFlex
        ? t('front_page_choice.time_box_registration.flex')
        : t('front_page_choice.time_box_registration.overtime')
    case 'Work Hours':
      return t('front_page_choice.work_hours')
    case 'Car Allowance':
      return t('front_page_choice.car_allowance')
    case 'Leave Registration':
      return t('front_page_choice.leave_registration')
    case 'Salary Registration':
      return t('front_page_choice.salary_registration')
    case 'Project Registration':
      return t('front_page_choice.project_registration')
    case 'Reimbursement Vouchers':
      return t('front_page_choice.reimbursement_vouchers')
    case 'One Time Pay Commission':
      return t('front_page_choice.one_time_pay_commission')
    case 'One Time Pay Travel Allowance':
      return t('front_page_choice.one_time_pay_travel_allowance')
    case 'Default':
      return t('front_page_choice.default')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown choice type name: `' + choice + '`')
      }
      return t('common.unknown')
  }
}
